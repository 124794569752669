import Company from '@/shared/models/company'
import statusEnum from '@/shared/enums/status'
export default class Account {
  constructor({
    id,
    companyID,
    company,
    groupID,
    groupName,
    name,
    login,
    rg,
    cpf,
    email,
    cellPhone,
    phone,
    currentPassword,
    password,
    confirmPassword,
    isConfirmed,
    lastAccess,
    listProfile,
    teacherID,
    status
  } = {}) {
    this.id = id || '00000000-0000-0000-0000-000000000000',
    this.companyID = companyID,
    this.company = company ? new Company(company) : undefined,
    this.groupID = groupID,
    this.groupName = groupName || '',
    this.name = name,
    this.login = login,
    this.rg = rg,
    this.cpf = cpf,
    this.email = email,
    this.cellPhone = cellPhone,
    this.phone = phone,
    this.currentPassword = currentPassword,
    this.password = password,
    this.confirmPassword = confirmPassword,
    this.isConfirmed = isConfirmed,
    this.lastAccess = lastAccess,
    this.listProfile = listProfile,
    this.teacherID = teacherID
    this.status = status
    this.statusText = this.getStatus(status)
  }

  getStatus(status){
    const statusObj = Object.values(statusEnum).find(element => element.id === status)

    if(!statusObj)
      return ''

    return statusObj.name
  }
}
