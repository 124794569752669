export default class AccountValidator {
    constructor() {
      this.entity = null;
      this.isValid = true;
      this.errors = [];
    }
  
    validate(account) {
      this.entity = account;
      this.isValid = true;
      this.errors = [];
      return this;
    }
  
    completely() {
      return this
        //.group()
        .name()
        .email()
        .cellPhone()
        .password()
        .confirmPassword()
    }
  
    completelyMyAccount() {
      return this
        .name()
        .email()
        .password()
        .confirmPassword()
        .currentPassword()
    }
  
    withoutPassword() {
      return this
        //.group()
        .currentPassword()
        .name()
        .email()
        .cellPhone()
    }
  
    group() {
      if (!this.entity.groupID) {
        this.errors.push({
          prop: 'groupID',
          message: 'É necessário informar o grupo.',
        });
        this.isValid = false;
      }
      return this;
    }
  
    name() {
      if (!this.entity.name) {
        this.errors.push({
          prop: 'name',
          message: 'É necessário informar o nome.',
        });
        this.isValid = false;
      }
      return this;
    }
  
    email() {
      if (!this.entity.email) {
        this.errors.push({
          prop: 'email',
          message: 'É necessário informar o email.',
        });
        this.isValid = false;
      }
      return this;
    }
  
    cellPhone() {
      if (!this.entity.cellPhone) {
        this.errors.push({
          prop: 'cellPhone',
          message: 'É necessário informar o telefone celular.',
        });
        this.isValid = false;
      }
      return this;
    }
  
    password() {
      if (!this.entity.password) {
        this.errors.push({
          prop: 'password',
          message: 'É necessário informar a senha.',
        });
        this.isValid = false;
        return this;
      }
  
      if (this.entity.password.length < 6) {
        this.errors.push({
          prop: 'password',
          message: 'A senha deve ter pelo menos 6 caracteres.',
        });
        this.isValid = false;
      }
  
      return this;
    }
  
    currentPassword() {
      if (!this.entity.currentPassword) {
        this.errors.push({
          prop: 'currentPassword',
          message: 'É necessário informar a senha atual.',
        });
        this.isValid = false;
        return this;
      }
  
      return this;
    }
  
    confirmPassword() {
      if (this.entity.password !== this.entity.confirmPassword) {
        this.errors.push({
          prop: 'confirmPassword',
          message: 'As senhas não conferem.',
        });
        this.isValid = false;
      }
  
      return this;
    }
  }
  