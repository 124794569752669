import BreadCrumb from "@/components/layout/BreadCrumb/breadCrumb.vue"
import AccountRepository from '@/shared/http/repositories/access/account'
import { IsSuccessCode } from '@/shared/utils/API'
import { loading, toast } from '@/shared/utils/UIHelper'
import Account from '@/shared/models/account'
import AccountValidator from '@/shared/services/validators/account'

const validator = new AccountValidator();

export default {
  components: {
    [BreadCrumb.name]: BreadCrumb
  },

  data: () => ({
    account: {},
    showModalDeletePicture: false,
    profileRewritePassword: null,
    confirmPassword: false,
    profilePassword: null,
    profileImg: null,
    buttonSave: false,
    showProfileImg: false
  }),

  mounted() {
    this.getMyAccount()
  },

  computed: {
    breadCrumb() {
      return {
        breadcrumbItems: [
          {
            text: "Dashboard",
            href: this.$router.resolve({ name: "Dashboard" }).href
          },
          {
            text: "Minha Conta"
          }
        ]
      };
    },
  },

  methods: {
    rewritePassword () {
      this.confirmPassword = true
    },

    getMyAccount(){
      loading.push();
      AccountRepository.GetMyAccount()
        .then(res => {
          if(!IsSuccessCode(res)) return;

          this.account = new Account(res.data.data)
          loading.pop();
          return Promise.resolve()
      })
      .catch(() => {
        loading.pop()
        toast.error('Erro ao carregar usuário', 'ERRO')
      })
    },

    save() {
      loading.push()

      const isChangingPassord = this.account.password || this.account.confirmPassword

      const validation = isChangingPassord
        ? validator.validate(this.account).completelyMyAccount()
        : validator.validate(this.account).withoutPassword()

      if (!validation.isValid) {
        toast.warning(validation.errors[0].message)
        loading.pop()
        return false
      }

      AccountRepository.UpdateMyAccount(this.account)
        .then(res => {
          if (!IsSuccessCode(res) && res.data.code === -8) {
            return Promise.reject('Senha atual inválida.');
          }

          if (!IsSuccessCode(res)) {
            return Promise.reject();
          }

          loading.pop()
          toast.success('Conta atualizada com sucesso', 'MINHA CONTA')
          Promise.resolve()
          return this.$router.replace({ name: 'Dashboard' })
        })
        .catch((error) => {
          loading.pop();
          toast.error(error || 'Erro ao atualizar conta.');
          return Promise.reject();
        })
    }
  },

  checkPassword () {

    const isNewPassordValid = this.profilePassword === this.profileRewritePassword

    if(isNewPassordValid) return

    this.profilePassword = null
    this.profileRewritePassword = null
    toast.warning('As senhas não conferem', 'SENHA INVÁLIDA')

    return
  }
}